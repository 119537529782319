import React from "react";
import Header from '../Header';
import AvisoTaxa from "../components/AvisoTaxa";

export function Termo() {

    const redirectPgrs = () => {
        window.location.href = '/termo/pgrs/coaafi/';
    }

    const redirectPgrccCnpj = () => {
        window.location.href = '/termo/pgrcc/juridica/coaafi/';
    }

    const redirectPgrccCpf = () => {
        window.location.href = '/termo/pgrcc/fisica/coaafi/';
    }

    return (
        <div>
            <Header />
            <main className='mb-5 pb-5'>
                <div className="container mt-4">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-8 container-formulario">
                            <p className="text-center">Selecione o Termo do Compromisso desejado.</p>
                            <div className="d-flex justify-content-center mb-4">
                                <button className="btn btn-primary pgrs me-3" onClick={redirectPgrs} type="button">PGRS</button>
                                <button type="button" className="btn btn-primary pgrcc" data-bs-toggle="modal" data-bs-target="#exampleModal">PGRCC</button>
                            </div>
                        </div>
                    </div>

                    <AvisoTaxa />
                </div>

                <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Pessoa Representante</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body justify-content-center d-flex py-5">
                                <button type="button" className="btn btn-primary me-3" onClick={redirectPgrccCpf}>Pessoa Física</button>
                                <button type="button" className="btn btn-primary" onClick={redirectPgrccCnpj}>Pessoa Jurídica</button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}
