import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

function GraficoPainel() {

    // TOTAL
    const [totalPgrs, setTotalPgrs] = useState([]);
    const [totalP, setTotalP] = useState([]);
    const [totalPC, setTotalPC] = useState([]);


    // PGRCC CPF
    const [janPC, setJanPC] = useState([]);
    const [fevPC, setFevPC] = useState([]);
    const [marPC, setMarPC] = useState([]);
    const [abrPC, setAbrPC] = useState([]);
    const [maiPC, setMaiPC] = useState([]);
    const [junPC, setJunPC] = useState([]);
    const [julPC, setJulPC] = useState([]);
    const [agoPC, setAgoPC] = useState([]);
    const [setPC, setSetPC] = useState([]);
    const [outPC, setOutPC] = useState([]);
    const [novPC, setNovPC] = useState([]);
    const [dezPC, setDezPC] = useState([]);

    // PGRCC CNPJ
    const [janP, setJanP] = useState([]);
    const [fevP, setFevP] = useState([]);
    const [marP, setMarP] = useState([]);
    const [abrP, setAbrP] = useState([]);
    const [maiP, setMaiP] = useState([]);
    const [junP, setJunP] = useState([]);
    const [julP, setJulP] = useState([]);
    const [agoP, setAgoP] = useState([]);
    const [setP, setSetP] = useState([]);
    const [outP, setOutP] = useState([]);
    const [novP, setNovP] = useState([]);
    const [dezP, setDezP] = useState([]);

    // PGRS
    const [jan, setJan] = useState([]);
    const [fev, setFev] = useState([]);
    const [mar, setMar] = useState([]);
    const [abr, setAbr] = useState([]);
    const [mai, setMai] = useState([]);
    const [jun, setJun] = useState([]);
    const [jul, setJul] = useState([]);
    const [ago, setAgo] = useState([]);
    const [set, setSet] = useState([]);
    const [out, setOut] = useState([]);
    const [nov, setNov] = useState([]);
    const [dez, setDez] = useState([]);

    useEffect(() => {

        // TOTAL
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)')), (querySnapshot) => setTotalPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)')), (querySnapshot) => setTotalP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS')), (querySnapshot) => setTotalPgrs(querySnapshot.docs));


        // PGRCC CPF
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 1 + '/' + new Date().getYear())), (querySnapshot) => setJanPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 2 + '/' + new Date().getYear())), (querySnapshot) => setFevPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 3 + '/' + new Date().getYear())), (querySnapshot) => setMarPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 4 + '/' + new Date().getYear())), (querySnapshot) => setAbrPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 5 + '/' + new Date().getYear())), (querySnapshot) => setMaiPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 6 + '/' + new Date().getYear())), (querySnapshot) => setJunPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 7 + '/' + new Date().getYear())), (querySnapshot) => setJulPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 8 + '/' + new Date().getYear())), (querySnapshot) => setAgoPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 9 + '/' + new Date().getYear())), (querySnapshot) => setSetPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 10 + '/' + new Date().getYear())), (querySnapshot) => setOutPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 11 + '/' + new Date().getYear())), (querySnapshot) => setNovPC(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CPF)'), where('paraGrafico', '==', 12 + '/' + new Date().getYear())), (querySnapshot) => setDezPC(querySnapshot.docs));

        // PGRCC CNPJ
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 1 + '/' + new Date().getYear())), (querySnapshot) => setJanP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 2 + '/' + new Date().getYear())), (querySnapshot) => setFevP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 3 + '/' + new Date().getYear())), (querySnapshot) => setMarP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 4 + '/' + new Date().getYear())), (querySnapshot) => setAbrP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 5 + '/' + new Date().getYear())), (querySnapshot) => setMaiP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 6 + '/' + new Date().getYear())), (querySnapshot) => setJunP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 7 + '/' + new Date().getYear())), (querySnapshot) => setJulP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 8 + '/' + new Date().getYear())), (querySnapshot) => setAgoP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 9 + '/' + new Date().getYear())), (querySnapshot) => setSetP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 10 + '/' + new Date().getYear())), (querySnapshot) => setOutP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 11 + '/' + new Date().getYear())), (querySnapshot) => setNovP(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRCC(CNPJ)'), where('paraGrafico', '==', 12 + '/' + new Date().getYear())), (querySnapshot) => setDezP(querySnapshot.docs));

        // PGRS
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 1 + '/' + new Date().getYear())), (querySnapshot) => setJan(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 2 + '/' + new Date().getYear())), (querySnapshot) => setFev(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 3 + '/' + new Date().getYear())), (querySnapshot) => setMar(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 4 + '/' + new Date().getYear())), (querySnapshot) => setAbr(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 5 + '/' + new Date().getYear())), (querySnapshot) => setMai(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 6 + '/' + new Date().getYear())), (querySnapshot) => setJun(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 7 + '/' + new Date().getYear())), (querySnapshot) => setJul(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 8 + '/' + new Date().getYear())), (querySnapshot) => setAgo(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 9 + '/' + new Date().getYear())), (querySnapshot) => setSet(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 10 + '/' + new Date().getYear())), (querySnapshot) => setOut(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 11 + '/' + new Date().getYear())), (querySnapshot) => setNov(querySnapshot.docs));
        onSnapshot(query(collection(db, 'registros'), where('planoGerenciamento', '==', 'PGRS'), where('paraGrafico', '==', 12 + '/' + new Date().getYear())), (querySnapshot) => setDez(querySnapshot.docs));
    }, []);

    const options = { responsive: true };
    const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const pgrs = [jan.length, fev.length, mar.length, abr.length, mai.length, jun.length, jul.length, ago.length, set.length, out.length, nov.length, dez.length];
    const pgrsP = [janP.length, fevP.length, marP.length, abrP.length, maiP.length, junP.length, julP.length, agoP.length, setP.length, outP.length, novP.length, dezP.length];
    const pgrsPC = [janPC.length, fevPC.length, marPC.length, abrPC.length, maiPC.length, junPC.length, julPC.length, agoPC.length, setPC.length, outPC.length, novPC.length, dezPC.length];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'PGRS',
                data: pgrs,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'PGRCC(CNPJ)',
                data: pgrsP,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'PGRCC(CPF)',
                data: pgrsPC,
                borderColor: 'rgb(255,193,7)',
                backgroundColor: 'rgba(255,193,7, 0.5)',
            }
        ],
    };

    const dataTwo = {
        labels: ['PGRS', 'PGRCC(CNPJ)', 'PGRCC(CPF)'],
        datasets: [
            {
                label: 'Termos',
                data: [totalPgrs.length, totalP.length, totalPC.length],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255,193,7, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255,193,7, 1)'
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div className="row mt-3">
            <div className="col-md-8 col-12">
                <Line options={options} data={data} />
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-4 d-flex justify-content-center">
                <Doughnut data={dataTwo} />
            </div>
        </div>
    );
}

export default GraficoPainel;
