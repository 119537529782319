import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { BotaoExcluir, BotaoVisualizar } from "./Botoes";
import 'react-toastify/dist/ReactToastify.css';
import HeaderPainel from "../components/HeaderPainel";
import { ToastContainer } from "react-toastify";

export function TermosPgrcc() {

    const [registros, setRegristros] = useState([]);
    const [search, setSearch] = useState('');
    const [filtrado, setFiltrado] = useState([]);

    useEffect(() => {
        const q = query(collection(db, 'registros'));
        onSnapshot(q, (querySnapshot) => {
            setRegristros(querySnapshot.docs.map(doc => ({
                data: doc.data(),
                id: doc.id
            })))
        })
    }, []);

    useEffect(() => {
        if(search !== '') {
            setFiltrado(
                registros.filter(
                    a => a?.data?.planoGerenciamento.includes('PGRCC') && (
                        a?.data?.representante?.nome.toLowerCase().includes(search.toLowerCase()) || 
                        a?.data?.empresa?.razaoSocial.toLowerCase().includes(search.toLowerCase()) ||
                        a?.data?.planoGerenciamento.toLowerCase().includes(search.toLowerCase()) ||
                        a?.data?.tecnico?.nome.toLowerCase().includes(search.toLowerCase())
                    )
                )
            )
        } else {
            setFiltrado(registros.filter(a => a?.data?.planoGerenciamento === 'PGRCC(CNPJ)' || a?.data?.planoGerenciamento === 'PGRCC(CPF)'));
        }
    }, [search, registros]);

    const subLimit = (string) => string.length > 25 ? string = string.substring(0, 25) + '...' : string;
    const dataFiltro = (data) => {
        let filtro = data.split('/');
        return `${filtro[2]}-${filtro[1]}-${filtro[0]}`;
    }

    return (
        <div>

            <HeaderPainel />

            <main className='mb-5 pb-5'>
                <div className="container mt-4">
                    <div className="row mb-3">
                        <div className="col-md-6 col-12">
                            <p className="d-flex align-items-center">
                                <a href="/painel" className="link-menu d-flex align-items-center me-2">
                                    <svg className="me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                                    Início
                                </a>
                                - Termos PGRCC
                            </p>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" /></svg>
                                </span>
                                <input onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" placeholder="Pesquisar..." aria-label="Pesquisar..." aria-describedby="addon-wrapping" />
                            </div>
                        </div>
                    </div>

                    <div className="d-md-block d-none">
                        <table className="table table-striped table-bordered">
                            <thead className="table-dark bg-gradient">
                                <tr>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Técnico</th>
                                    <th scope="col">Plano de Gerenciamento</th>
                                    <th scope="col">Data e Horário</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    filtrado.sort((x,y) => Date.parse(dataFiltro(y.data.data)) - Date.parse(dataFiltro(x.data.data))).map(function (e, index) {

                                        function idenfication() {
                                            if (e?.data?.empresa?.razaoSocial) {
                                                return subLimit(e?.data?.empresa?.razaoSocial.toUpperCase());
                                            } else {
                                                return subLimit(e?.data?.representante?.nome.toUpperCase());
                                            }
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>{idenfication()}</td>
                                                <td>{subLimit(e?.data?.tecnico?.nome.toUpperCase())}</td>
                                                <th>{e?.data?.planoGerenciamento.toUpperCase()}</th>
                                                <td>{e?.data?.data} - {e?.data?.horario}</td>
                                                <td>
                                                    <div className="d-grid gap-2 d-md-block text-center">
                                                        <BotaoVisualizar item={e?.id} />
                                                        <BotaoExcluir item={e?.id} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <ToastContainer />

                    <div className="d-md-none d-block">
                        {
                            filtrado.sort((x,y) => Date.parse(dataFiltro(y.data.data)) - Date.parse(dataFiltro(x.data.data))).map(function (e, index) {
                                function idenfication() {
                                    if (e?.data?.empresa?.razaoSocial) {
                                        return subLimit(e?.data?.empresa?.razaoSocial.toUpperCase());
                                    } else {
                                        return subLimit(e?.data?.representante?.nome.toUpperCase());
                                    }
                                }
                                return (
                                    <div className="row mb-4" key={index}>
                                        <div className="col-12 table-mobile">
                                            <p className="bg-dark text-white text-center py-2 m-0 fw-bold">{e?.data?.planoGerenciamento.toUpperCase()}</p>
                                            <p className="text-center py-1 m-0">{idenfication()}</p>
                                            <p className="text-center py-1 m-0">{subLimit(e?.data?.tecnico?.nome)}</p>
                                            <p className="text-center py-1 m-0">{e?.data?.data} - {e?.data?.horario}</p>
                                            <div className="d-block text-center m-auto py-2 button-mobile">
                                                <BotaoVisualizar item={e?.id} />
                                                <BotaoExcluir item={e?.id} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </main>
        </div>
    );
}
