import BotaoWpp from "../components/BotaoWppComunicado";

export function Comunicado() {
    return (
        <main className='mb-5 pb-5'>
            <div className="container mt-3">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="container-pix col-md-6">
                        <div className="mt-2">
                            <h4 className="fw-bold text-center">⚠️ Comunicado ⚠️</h4>
                            <div className='my-5'>
                                <p className='mt-4'>Prezados clientes, engenheiros e técnicos,</p>
                                <p className='mt-4'>Pedimos desculpas pelo transtorno que esta situação possa causar. Gostaríamos de informar que, devido ao nosso compromisso com a preservação do meio ambiente e o bem-estar de nossa comunidade, <span className='mark'>a cooperativa atingiu um total de 120 pontos coletados semanalmente.</span></p>
                                <p className='mt-4'>Com o intuito de não prejudicar os grandes geradores que estão em processo de elaboração de seus Planos de Gerenciamento de Resíduos Sólidos (PGRS), <span className='mark' >tomamos a decisão de desativar temporariamente o link de coleta</span>. Esta medida visa assegurar que todos os grandes geradores tenham a oportunidade de cumprir suas obrigações de forma adequada.</p>
                                <p className='mt-4'>Estamos à disposição para prestar assistência e esclarecer dúvidas através do número de telefone via <a href="https://api.whatsapp.com/send?phone=5545999264061&text=Ol%C3%A1,%20quero%20tirar%20d%C3%BAvidas%20sobre%20o%20comunicado%20do%20site%20da%20Coaafi!"> WhatsApp: (45) 9 9926-4061</a>. Estamos comprometidos em oferecer o melhor atendimento e suporte a todos os nossos clientes.</p>
                                <p className='mt-4'>Agradecemos pela compreensão e colaboração de todos. Continuaremos a trabalhar para manter nosso compromisso com a sustentabilidade e a qualidade dos serviços prestados.</p>
                                <p className='mt-4'></p>
                                <p className='mt-4'>Atenciosamente, <br />
                                    Cleusa Cordeiro da Silva <br /> Presidente da Cooperativa
                                </p>
                            </div>
                        </div>
                        <BotaoWpp />
                    </div>
                </div>
            </div>
        </main>
    );
}
