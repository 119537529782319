function Header() {
    return (
        <header className="mt-3 mt-md-5">
            <div className="container text-center">
                <img src="/img/logo.png" />
            </div>
        </header>
    );
}

export default Header;
