function AsideCpnjPgrcc() {
    return (
        <aside className="d-none">
            <div className="campos-cnpj">
                <div className="mb-3">
                    <label className="form-label">Razão Social</label>
                    <input type="text" className="form-control" id="razaoSocial" required />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-3">
                            <label className="form-label">E-mail</label>
                            <input type="email" className="form-control" id="email" required/>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-3">
                            <label className="form-label">Telefone/Celular</label>
                            <input type="text" className="form-control" id="telefone" required/>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default AsideCpnjPgrcc;
