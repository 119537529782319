import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Painel } from './pages/Painel';
import { TermosPendentes } from './pages/TermosPendentes';
import { TermosEnviados } from './pages/TermosEnviados';
import { Termo } from './pages/Termo';
import { Protocolo } from './pages/Protocolo';
import { NotFound } from './pages/NotFound';
import { Pix } from './pages/Pix';
import { Pgrs } from './pages/Pgrs';
import { PgrccCnpj } from './pages/PgrccCnpj';
import { PgrccCpf } from './pages/PgrccCpf';
import { Configuracoes } from './pages/Configuracoes';
import { TermosPgrs } from './pages/TermosPgrs';
import { TermosPgrcc } from './pages/TermosPgrcc';
import { Login } from './pages/Login';
import { AuthGoogleProvider } from './contexts/AuthGoogleProvider';
import '../src/css/index.css';
import { Comunicado } from './pages/comunicado';

function App() { 
  return (
    <Routes>
      <Route path='/' element={<Home />} />

      {/* COMUNICADO */}
      <Route path='/termo' element={<Comunicado />} />
      <Route path='/termo/pgrs' element={<Comunicado />} />
      <Route path='/termo/pgrcc/juridica' element={<Comunicado />} />
      <Route path='/termo/pgrcc/fisica' element={<Comunicado />} />
      <Route path='/pagamento' element={<Comunicado />} />

      {/* LINKS PROVISORIOS */}
      <Route path='/termo/coaafi' element={<Termo />} />
      <Route path='/termo/pgrs/coaafi' element={<Pgrs />} />
      <Route path='/termo/pgrcc/juridica/coaafi' element={<PgrccCnpj />} />
      <Route path='/termo/pgrcc/fisica/coaafi' element={<PgrccCpf />} />
      <Route path='/pagamento/coaafi' element={<Pix />} />

      {/* <Route path='/termo' element={<Comunicado />} />
      <Route path='/termo/pgrs' element={<Pgrs />} />
      <Route path='/termo/pgrcc/juridica' element={<PgrccCnpj />} />
      <Route path='/termo/pgrcc/fisica' element={<PgrccCpf />} /> 
      <Route path='/pagamento' element={<Pix />} />
      */}

      <Route path='/protocolo/:slug' element={<Protocolo />} />
      <Route path='/*' element={<NotFound />} />
      <Route path='/login' element={<Login />} />
      <Route path='/painel' element={<AuthGoogleProvider><Painel /></AuthGoogleProvider>} />
      <Route path='/painel/pendentes' element={<AuthGoogleProvider><TermosPendentes /></AuthGoogleProvider>} />
      <Route path='/painel/enviados' element={<AuthGoogleProvider><TermosEnviados /></AuthGoogleProvider>} />
      <Route path='/painel/pgrs' element={<AuthGoogleProvider><TermosPgrs /></AuthGoogleProvider>} />
      <Route path='/painel/pgrcc' element={<AuthGoogleProvider><TermosPgrcc /></AuthGoogleProvider>} />
      <Route path='/painel/configuracoes' element={<AuthGoogleProvider><Configuracoes /></AuthGoogleProvider>} />
    </Routes>
  );
}

export default App;
