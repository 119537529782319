import { deleteDoc, doc, getDoc } from "firebase/firestore";
import React from "react";
import { db } from "../firebase";
import { toast } from 'react-toastify';
import verPdfPgrs from "../services/verPdfPgrs";
import verPdfPgrccCnpj from "../services/verPdfPgrccCnpj";
import verPdfPgrccCpf from "../services/verPdfPgrccCpf";
import geradorPdfPgrs from "../services/geradorPdfPgrs";
import geradorPdfPgrccCnpj from "../services/geradorPdfPgrccCnpj";
import geradorPdfPgrccCpf from "../services/geradorPdfPgrccCpf";

export class BotaoEnviar extends React.Component {
    handleClick = () => {
        if (window.confirm("Você realmente deseja enviar esse registro?") === true) {
            var array = this.props.item.split('&&&');
            if(array[1] === 'PGRS') {
                geradorPdfPgrs(array[0]);
            } else if (array[1] === 'PGRCC(CNPJ)') {
                geradorPdfPgrccCnpj(array[0]);
            } else if (array[1] === 'PGRCC(CPF)') {
                geradorPdfPgrccCpf(array[0]);
            }
        }
    }
    render() {
        return (
            <button onClick={this.handleClick} type="button" className="btn btn-success me-2">Enviar</button>
        );
    }
}

export class BotaoVisualizar extends React.Component {
    handleClick = () => {
        var path = doc(db, 'registros', this.props.item);
        getDoc(path)
            .then((response) => {
                if (response.data().empresa) {
                    verPdfPgrs(this.props.item);
                } else if (response.data().representante.cnpj) {
                    verPdfPgrccCnpj(this.props.item);
                } else if (response.data().representante.cpf) {
                    verPdfPgrccCpf(this.props.item);
                }
            })
    }
    render() {
        return (
            <button onClick={this.handleClick} type="button" className="btn btn-primary me-2">Visualizar</button>
        );
    }
}

export class BotaoExcluir extends React.Component {
    handleClick = () => {
        if (window.confirm("Você realmente deseja excluir esse registro?") === true) {

            // Pasta db google
            const pathFirestore = doc(db, 'registros', this.props.item);

            deleteDoc(pathFirestore)
                .then(() => {
                    toast.info('Registro excluido com Sucesso!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .catch((error) => {
                    toast.error('Erro ao excluir o registro no Google Firastore', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })

        }
    }
    render() {
        return (
            <button onClick={this.handleClick} type="button" className="btn btn-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
            </button>
        );
    }
}
