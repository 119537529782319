import { Navigate } from "react-router-dom";

export const AuthGoogleProvider = ({ children }) => {

    const sessionToken = sessionStorage.getItem("@AuthFirebase:token");
    const sessionUser = sessionStorage.getItem("@AuthFirebase:user");

    if(!sessionToken || !sessionUser) {
      return <Navigate to="/login" />
    } else {
      return children;
    }

  
}