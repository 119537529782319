import { CopyToClipboard } from 'react-copy-to-clipboard';
import BotaoWpp from '../components/BotaoWpp';

export function Pix() {

    const botaoClicado = () => {
        alert('Código pix copiado!');
    }

    return (
        <main className='mb-5 pb-5'>
            <div className="container mt-3">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="container-pix text-center col-md-6">
                        <div className="pix p-4 border rounded mt-2 bg-white">
                            <h4 className="fw-bold">PIX gerado com sucesso</h4>
                            <p>Estamos aguardando o pagamento!</p>
                            <div className="alert alert-success" role="alert">
                                Para agilizar o recebimento do termo, ou para outras formas de pagamento, envie o comprovante para o nosso número de contato clicando no botão abaixo.
                                <BotaoWpp />
                            </div>

                            <hr />

                            <img className="my-3" src="/img/qrcode-pix.png" />
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" value='00020126670014br.gov.bcb.pix0122coaafi.termo@gmail.com0219Taxa administrativa5204000053039865406180.005802BR5925COOPERATIVA DOS AGENTES A6013Foz do Iguacu610985870-04062290525IWM0048763931667746577183630419D9' readOnly />
                            </div>

                            <CopyToClipboard text={'00020126670014br.gov.bcb.pix0122coaafi.termo@gmail.com0219Taxa administrativa5204000053039865406180.005802BR5925COOPERATIVA DOS AGENTES A6013Foz do Iguacu610985870-04062290525IWM0048763931667746577183630419D9'}>
                                <button type="button" onClick={botaoClicado} className="btn btn-primary mt-3">Copiar Código</button>
                            </CopyToClipboard>

                            <div className="p-4 border rounded mt-4 text-start como-pagar">
                                <h4 className="fw-bold">Como realizar o pagamento</h4>
                                <div className="d-flex mb-2 mt-4">
                                    <img src="/img/mobil.svg" />
                                    <p className="ms-3">Abra o aplicativo de seu banco e selecione <b>QR Code</b> na opção de <b>pagamento por PIX.</b></p>
                                </div>
                                <div className="d-flex">
                                    <img src="/img/scan_blu.svg" />
                                    <p className="ms-3"><b>Copie o código</b> acima clicando no botão ou utilize a câmera do celular para <b>escanear o QR Code</b>, certifique-se que os dados estão corretos e finalize o pagamento.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
