import React, { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import HeaderPainel from "../components/HeaderPainel";

export function Configuracoes() {
    
    const apenasNumeros = (str) => str.replace(/[^0-9]/g, '');

    const [fone, setSetFone] = useState([]);
    const [matrizE, setMatrizE] = useState([]);
    const [matrizT, setMatrizT] = useState([]);
    const [b1, setB1] = useState([]);
    const [b2, setB2] = useState([]);
    const [b3, setB3] = useState([]);
    const [b4, setB4] = useState([]);
    const [b5, setB5] = useState([]);
    const [b6, setB6] = useState([]);
    const [b7, setB7] = useState([]); 

    // Pasta registro db
    var path = doc(db, 'empresa', 'S7gh2YZKeCMymBcBeCor');
    getDoc(path)
        .then((data) => {
            setSetFone(data.data().numeroSuporte);
            setMatrizE(data.data().matriz.endereco);
            setMatrizT(data.data().matriz.fone);
            setB1(data.data().barracoes.b1);
            setB2(data.data().barracoes.b2);
            setB3(data.data().barracoes.b3);
            setB4(data.data().barracoes.b4);
            setB5(data.data().barracoes.b5);
            setB6(data.data().barracoes.b6);
            setB7(data.data().barracoes.b7);
        })

    const atualizarRegistro = async () => {

        const dataUpdate = {
            numeroSuporte: apenasNumeros(document.querySelector('#numeroSuporte').value),
            matriz: {
                endereco: document.querySelector('#ME').value,
                fone: apenasNumeros(document.querySelector('#MT').value),
            },
            barracoes: {
                b1: {
                    bairro: document.querySelector('#B1-B').value,
                    endereco: document.querySelector('#B1-T').value,
                    status: true,
                },
                b2: {
                    bairro: document.querySelector('#B2-B').value,
                    endereco: document.querySelector('#B2-T').value,
                    status: true,
                },
                b3: {
                    bairro: document.querySelector('#B3-B').value,
                    endereco: document.querySelector('#B3-T').value,
                    status: true,
                },
                b4: {
                    bairro: document.querySelector('#B4-B').value,
                    endereco: document.querySelector('#B4-T').value,
                    status: true,
                },
                b5: {
                    bairro: document.querySelector('#B5-B').value,
                    endereco: document.querySelector('#B5-T').value,
                    status: true,
                },
                b6: {
                    bairro: document.querySelector('#B6-B').value,
                    endereco: document.querySelector('#B6-T').value,
                    status: true,
                },
                b7: {
                    bairro: document.querySelector('#B7-B').value,
                    endereco: document.querySelector('#B7-T').value,
                    status: true,
                },
            }
        }

        updateDoc(path, dataUpdate)
            .then(() => {
                toast.success('Dados Atualizados com Sucesso!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };

    return (
        <div>

            <HeaderPainel />

            <main className='mb-5 pb-5'>
                <div className="container mt-4 position-relative">
                    <div>
                        <p className="d-flex align-items-center">
                            <a href="/painel" className="link-menu d-flex align-items-center me-2">
                                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                                Início
                            </a>
                            - Configurações da Empresa
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="mb-3">
                                <label className="form-label">Fone Suporte</label>
                                <input type="text" className="form-control" id="numeroSuporte" defaultValue={fone} placeholder="fone" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <h5 className="fw-bold mt-3">Matriz</h5>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <label className="form-label">Endereço</label>
                                <input type="text" className="form-control" id="ME" defaultValue={matrizE} placeholder="Endreço" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <label className="form-label">Telefone</label>
                                <input type="text" className="form-control" id="MT" defaultValue={matrizT} placeholder="Telefone" />
                            </div>
                        </div>
                    </div>


                        <h5 className="fw-bold mt-3">Barracoes</h5>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B1-B" defaultValue={b1.bairro}  />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B1-T" defaultValue={b1.endereco} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B2-B" defaultValue={b2.bairro}  />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B2-T" defaultValue={b2.endereco}  />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B3-B" defaultValue={b3.bairro} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B3-T" defaultValue={b3.endereco}  />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B4-B" defaultValue={b4.bairro} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B4-T" defaultValue={b4.endereco}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B5-B" defaultValue={b5.bairro} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B5-T" defaultValue={b5.endereco}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B6-B" defaultValue={b6.bairro} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B6-T" defaultValue={b6.endereco}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="B7-B" defaultValue={b7.bairro}/>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="B7-T" defaultValue={b7.endereco} />
                                </div>
                            </div>
                        </div>



                    <button type="submit" className="btn btn-primary botao-enviar mt-3" onClick={atualizarRegistro}>Enviar</button>
                </div>

                <ToastContainer />
                
            </main>

        </div >
    );
}
