import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header';
import verPdfPgrs from "../services/verPdfPgrs";
import verPdfPgrccCnpj from "../services/verPdfPgrccCnpj";
import verPdfPgrccCpf from "../services/verPdfPgrccCpf";

export function Protocolo() {

    const params = useParams();

    const [nomeTec, setNomeTec] = useState('');
    const [plano, setPlano] = useState('');

    /// Pegar registro do banco de dados
    var path = doc(db, 'registros', params.slug);
    getDoc(path)
        .then((data) => {
            setNomeTec(data?.data().dataEnvio);
            setPlano(data?.id);
        })
        .catch(() => {
            toast.error('Número de protoloco não existente, tente novamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })

    function handClick() {
        /// Pegar registro do banco de dados
        var path = doc(db, 'registros', params.slug);
        getDoc(path)
            .then((response) => {
                if (response.data().planoGerenciamento === 'PGRS') {
                    verPdfPgrs(params.slug);
                } else if (response.data().planoGerenciamento === 'PGRCC(CNPJ)') {
                    verPdfPgrccCnpj(params.slug);
                } else if (response.data().planoGerenciamento === 'PGRCC(CPF)') {
                    verPdfPgrccCpf(params.slug);
                }
            })
            .catch(() => {
                toast.error('Erro ao visulaizar PDF!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }

    return (
        <div>
            <Header />
            <main className='mb-5 pb-5'>
                <div className="container text-center mt-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 col-12">
                            <div class="alert alert-success text-center" role="alert">
                                Termo de compromisso <b>COAAFI (Cooperativa dos Agentes Ambientais de Foz do Iguaçu)</b>,
                                documento gerado através do link <a lass="alert-link" href="https://coaafi.com.br/termo">coaafi.com.br/termo</a>,
                                e assinado digitalmente pelo(a) <b>Presidente</b> do conselho.
                                <div className="row mt-4">
                                    <div className="col-md-6 col-12 text-md-start text-center mb-md-0 mb-1">
                                        <small className="m-0"><b>Protocolo: </b>{plano}</small>
                                    </div>
                                    <div className="col-md-6 col-12 text-md-end text-center">
                                        <small className="m-0"><b>Data:</b> {nomeTec}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" onClick={handClick} className="btn btn-danger pdf mt-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z" /></svg>
                    </button>
                    <ToastContainer />
                </div>
            </main>
        </div>
    );
}
