import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import QRCodeLink from "qrcode";
import { ASSINATURA_CANETA, LOGO_CABECARIO, LOGO_TRANSPARENTE, SERVER_EMAIL, URL_PADRAO } from "../padrao";
import axios from "axios";
import { toast } from "react-toastify";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

function geradorPdfPgrs(id) {

    QRCodeLink.toDataURL(`${URL_PADRAO}protocolo/${id}`, {
        width: 100,
        margin: 1
    })
        .then((response) => {

            // QRCode em base64
            const qrcodePDF = response;

            // Pasta registro db
            var path = doc(db, 'registros', id);
            getDoc(path)
                .then((response) => {

                    // Pasta empresa db
                    var pathE = doc(db, 'empresa', 'S7gh2YZKeCMymBcBeCor');
                    getDoc(pathE)
                        .then((data) => {

                            

                            const matrizE = data.data().matriz.endereco;
                            const matrizT = data.data().matriz.fone;
                            const ball = data.data().barracoes;

                            const celShow = (str) => str.length > 10 ? str.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4") : str.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
                            
                            const planoGerenciamento = response.data().planoGerenciamento;

                            
                            const dataArquivo = new Date().toLocaleDateString();

                            const razaoSocial = response.data().empresa.razaoSocial;
                            const cnpj = response.data().empresa.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                            const endereco = response.data().empresa.endereco.split(',');
                            const numero = response.data().empresa.numero;
                            const cep = response.data().empresa.cep.replace(/^(\d{5})(\d{3})/, "$1-$2");
                            const cidade = response.data().empresa.cidadeEstado.split('-');
                            const socio = response.data().empresa.socio;
                            const telefone = response.data().empresa.telefone;
                            const email = response.data().empresa.email;

                            const nomeTec = response.data().tecnico.nome;
                            const registro = response.data().tecnico.registro !== '' ? response.data().tecnico.registro : 'Não possui registro';
                            const emailTec = response.data().tecnico.email;
                            const telefoneTec = response.data().tecnico.telefone;

                            const papel = parseFloat(response.data().residuos.papel);
                            const plastico = parseFloat(response.data().residuos.plastico);
                            const metal = parseFloat(response.data().residuos.metal);
                            const vidro = parseFloat(response.data().residuos.vidro);

                            const totalDecimal = (el) => parseFloat(el) > 0 && parseFloat(el) < 1 ? 1 : (el).toFixed(2);
                            const total = totalDecimal((papel + metal + plastico + vidro));

                            // data
                            function dataPdf() {
                                function formatarData(str) {
                                    var partes = str.split('/').map(Number);
                                    var data = new Date(partes[2], partes[1] - 1, partes[0]);
                                    return data.toLocaleString([], { year: 'numeric', month: 'long', day: 'numeric' });
                                }
                                return formatarData(new Date().toLocaleDateString())
                            }

                            // Capitalize
                            function capitalizeFirst(str) {
                                var subst = str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
                                return subst;
                            }

                            pdfMake.vfs = pdfFonts.pdfMake.vfs;

                            // Fonts
                            pdfMake.fonts = {
                                Roboto: {
                                    normal: URL_PADRAO + 'fonts/Roboto-Regular.ttf',
                                    bold: URL_PADRAO + 'fonts/Roboto-Bold.ttf',
                                    italics: URL_PADRAO + 'fonts/Roboto-Italic.ttf',
                                    bolditalics: URL_PADRAO + 'fonts/Roboto-BoldItalic.ttf'
                                },
                            }

                            const headerPdf = [
                                {
                                    image: LOGO_CABECARIO,
                                    width: 200,
                                    alignment: 'center',
                                    margin: [0, 20, 0, 0]
                                },
                                {
                                    text: ['Cooperativa dos Agentes Ambientais de Foz do Iguaçu \n CNPJ – 04.912.806/0001-07'],
                                    fontSize: 10,
                                    bold: true,
                                    italics: true,
                                    color: '#306868',
                                    alignment: 'center',
                                    margin: [0, 5, 0, 0]
                                },
                            ]

                            const mainPdf = [
                                {
                                    text: ['TERMO DE COMPROMISSO COAAFI – EMPRESA'],
                                    fontSize: 14,
                                    bold: true,
                                    alignment: 'center',
                                    margin: [0, 5, 0, 0]
                                },
                                {
                                    text: [
                                        'O presente Termo de Compromisso, entre a Cooperativa dos Agentes Ambientais de Foz do Iguaçu – COAAFI, entidade devidamente constituída, inscrita no CNPJ sob n° 04.912.806/0001-07, sito com sua matriz na ' + matrizE + ', aqui representada por', { text: 'CLEUSA CORDEIRO DA SILVA,', bold: true }, ' Rg: 7.095.529-6, CPF: 015.087.199-69, Cargo: Presidente, ora denominada como ', { text: 'Unidade Receptora,', bold: true, decoration: 'underline' }, ' e a empresa: ', { text: razaoSocial.toUpperCase(), bold: true }, ', CNPJ: ', { text: cnpj, bold: true }, ', localizada na ', { text: endereco[0].toUpperCase(), bold: true }, ', n°: ', { text: numero, bold: true }, ', Bairro: ', { text: endereco[1].toUpperCase(), bold: true }, ', CEP: ', { text: cep, bold: true }, ', na cidade de ', { text: cidade[0], bold: true }, ', aqui representada por ', { text: socio.toUpperCase(), bold: true }, ', Cargo: ', { text: 'Administrador', bold: true }, ', telefone: ', { text: celShow(telefone), bold: true }, ', e-mail:', { text: email.toUpperCase(), decoration: 'underline', link: 'mailto:' + email, color: '#0000FF' }, ', ora denominada de ', { text: 'Unidade Geradora.', bold: true, decoration: 'underline' }, 'O responsável pelo PGRS ', { text: nomeTec.toUpperCase(), bold: true }, ', Registro: ', { text: registro.toUpperCase(), bold: true }, ', e-mail: ', { text: emailTec.toUpperCase(), decoration: 'underline', link: 'mailto:' + emailTec, color: '#0000FF' }, ', telefone: ', { text: celShow(telefoneTec), bold: true }, ', ora denominado Responsável Técnico. O presente Termo de Compromisso tem por objetivo, regularizar a doação dos Resíduos Sólidos Recicláveis, produzidos pela Unidade Geradora, em conformidade com o Plano de Gerenciamento de Resíduos Sólidos, estabelecido pela Secretaria Municipal de Meio Ambiente e o decreto n° 28.368/2020.\n\n O presente Termo de Compromisso possui as seguintes cláusulas:'
                                    ],
                                    style: 'main'
                                },
                                {
                                    type: 'lower-alpha',
                                    separator: ')',
                                    ol: [
                                        {
                                            text: [
                                                'O ', { text: 'Responsável Técnico', bold: true }, ' se compromete em prover a capacitação e o treinamento inicial da Unidade Geradora, e de forma continuada acompanhar e orientar a segregação básica, manejo e armazenamento temporário dos resíduos sólidos passíveis de reciclagem.\n\n'
                                            ]
                                        },

                                        {
                                            text: [
                                                'A ', { text: 'Unidade Geradora', bold: true }, ' se compromete a realizar internamente a Coleta Seletiva de Resíduos Sólidos de acordo com as normas vigentes e compromissos assumidos no Plano de Gerenciamento de Resíduos Sólidos, celebrados entre si e o Órgão Público acima citado.\n\n'
                                            ]
                                        },
                                        [
                                            {
                                                text: [
                                                    'A ', { text: 'Unidade Geradora', bold: true }, ' se compromete a realizar a doação dos resíduos sólidos, passíveis de reciclagem abaixo identificado:\n\n\n\n'
                                                ]
                                            },
                                            {
                                                table: {
                                                    headerRows: 1,
                                                    widths: [200, 200],
                                                    body: [
                                                        [{ text: 'INDENTIFICAÇÃO QUANTITATIVA', fillColor: '#306868', color: '#fff', alignment: 'center', bold: true, fontSize: 14, colSpan: 2 }, {}],
                                                        [{ text: 'RESÍDUO CLASSE', style: 'tableTitle' }, { text: 'QUANTIDADE (Kg)', style: 'tableTitle' }],
                                                        [{ text: 'Papel', alignment: 'center' }, { text: papel, alignment: 'center' }],
                                                        [{ text: 'Plástico', alignment: 'center' }, { text: plastico, alignment: 'center' }],
                                                        [{ text: 'Metal', alignment: 'center' }, { text: metal, alignment: 'center' },],
                                                        [{ text: 'Vidro', alignment: 'center' }, { text: vidro, alignment: 'center' }],
                                                        [{ text: 'TOTAL', style: 'tableTitle' }, { text: total, alignment: 'center', bold: true }],
                                                    ],
                                                },
                                                margin: [30, 0, 0, 20]
                                            }
                                        ],
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Geradora', bold: true }, ' se compromete a se cadastra no sistema ', { text: 'SINIR', bold: true }, ' – Sistema Nacional de Informações sobre a gestão dos Resíduos Sólidos e Emitir o ', { text: 'MTR', bold: true }, ' – Manifesto de Transporte de Resíduos conforme a Portaria nº 280, de 29 de junho de 2020, emitida pelo Ministério do Meio Ambiente, a utilização do MTR online é obrigatória em todo o território brasileiro a partir de 1º de janeiro de 2021.\n\n'
                                            ]
                                        },
                                        [
                                            {
                                                text: [
                                                    'A ', { text: 'Unidade Receptora', bold: true }, ' através de seu(s) cooperado(s), se compromete(m) a ', { text: 'receber', bold: true }, ' nos 7 (Sete) Centros de Triagens na cidade de Foz do Iguaçu resíduos sólidos que tenha como destino o processo de reciclagem, a coleta no local da unidade geradora poderá ser realiza conforme a logística de coleta da cooperativa e mediante articulação previa com o responsável.\n\n\n\n\n\n\n\n\n\n\n\n'
                                                ]
                                            },
                                            {
                                                table: {
                                                    body: [
                                                        [{ text: 'ENDEREÇOS DOS BARRACÕES', fillColor: '#306868', color: '#fff', alignment: 'center', bold: true, fontSize: 14, colSpan: 2 }, {}],
                                                        [{ text: 'BARRACÕES', style: 'tableTitle' }, { text: 'ENDEREÇO', style: 'tableTitle' }],
                                                        ball.b1.status === true ? [{ text: ball.b1.bairro }, { text: ball.b1.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b2.status === true ? [{ text: ball.b2.bairro }, { text: ball.b2.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b3.status === true ? [{ text: ball.b3.bairro }, { text: ball.b3.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b4.status === true ? [{ text: ball.b4.bairro }, { text: ball.b4.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b5.status === true ? [{ text: ball.b5.bairro }, { text: ball.b5.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b6.status === true ? [{ text: ball.b6.bairro }, { text: ball.b6.endereco }] : [{ text: '--' }, { text: '--' }],
                                                        ball.b7.status === true ? [{ text: ball.b7.bairro }, { text: ball.b7.endereco }] : [{ text: '--' }, { text: '--' }],
                                                    ],
                                                },
                                                margin: [0, 0, 0, 30]
                                            }
                                        ],
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Receptora', bold: true }, ' se compromete a emitir o Certificado de Destinação Final – CDF conforme o MTR emitido pelo sistema SINER \n\n'
                                            ]
                                        },
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Receptora', bold: true }, ' se compromete em acompanhar os trabalhos do cooperado(s) bem como proceder a indicação de outros, caso o mesmo não cumpra o acordo aqui firmado.\n'
                                            ]
                                        },

                                    ],
                                    style: 'main'
                                },
                                {
                                    text: ['\n\n\n\n\n\n\n\n\n\nPor estarem de acordo, o presente Termo de Compromisso será assinado por todas as partes.\n\n'],
                                    style: 'main'
                                },
                                {
                                    text: ['Foz do Iguaçu, ' + dataPdf()],
                                    alignment: 'right'
                                },
                                {
                                    text: [
                                        '\n\n\n\n________________________________________________________\n',
                                        { text: 'Responsável Técnico –', bold: true }, '\n' + capitalizeFirst(nomeTec)
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: [
                                        '\n\n\n\n\n\n\n________________________________________________________\n',
                                        { text: 'Unidade Geradora –\n' + razaoSocial, bold: true }, '\n' + capitalizeFirst(socio) + '\n\n\n\n\n\n\n'
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    image: ASSINATURA_CANETA,
                                    width: 170,
                                    alignment: 'center',
                                    margin: [0, 0, 60, -60]
                                },
                                {
                                    image: LOGO_TRANSPARENTE,
                                    width: 100,
                                    alignment: 'right',
                                    margin: [0, 0, 50, 0]
                                },
                                {
                                    columns: [
                                        {
                                            width: 80,
                                            text: [
                                                'CLEUSA CORDEIRO DA SILVA'
                                            ],
                                            alignment: 'center',
                                            bold: true
                                        },
                                        {
                                            width: 80,
                                            text: [
                                                'Assinado Digitalmente por Cleusa Cordeiro da Silva Email: ', { text: 'coaafi.termo@gmail.com', decoration: 'underline', link: 'mailto:coaafi.termo@gmail.com', color: '#0000FF' }, ' Data: ', new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), ' Protocolo: ', id
                                            ],
                                            fontSize: 6,
                                            alignment: 'justify'
                                        },
                                        {
                                            width: 45,
                                            image: qrcodePDF,
                                        }
                                    ],
                                    columnGap: 5,
                                    margin: [255, -30, 0, 0],
                                },
                                {
                                    text: [
                                        '________________________________________________________\n',
                                        { text: 'Unidade Receptora – ', bold: true }, 'COAAFI\n Cleusa Cordeiro da Silva \n ', { text: 'Presidente', bold: true }
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: ['\n\nO presente termo tem como validade o período de um ano, a partir da assinatura da unidade receptora'],
                                    style: 'main'
                                }

                            ]

                            function Rodape(currentPage, pageCount) {
                                return {
                                    text: [
                                        matrizE + ' – Foz do Iguaçu – Paraná\n',
                                        'E-mail: coaafi.termo@gmail.com – Fone: ' + celShow(matrizT) + ' \n',
                                        'Página ' + currentPage + ' de ' + pageCount,
                                        {
                                            text: '\nAssinado Digitalmente Data: ' + new Date().toLocaleDateString() + ' \nProtocolo: ' + id,
                                            fontSize: 6,
                                            bold: false,
                                            italics: false,
                                            color: '#000',
                                            alignment: 'right',
                                            margin: [50, 0, 0, 0]
                                        }
                                    ],
                                    fontSize: 10,
                                    bold: true,
                                    italics: true,
                                    color: '#306868',
                                    alignment: 'center',
                                    margin: [15, 15, 15, 30],
                                }
                            }

                            const gerar = {
                                pageSize: 'A4',
                                pageMargins: [50, 110, 50, 70],
                                header: [headerPdf],
                                content: [mainPdf],
                                footer: Rodape,
                                defaultStyle: { font: 'Roboto' },
                                styles: {
                                    main: {
                                        fontSize: 12,
                                        alignment: 'justify',
                                        lineHeight: 1.5,
                                        margin: [0, 20, 0, 0]
                                    },
                                    tableTitle: {
                                        bold: true,
                                        alignment: 'center',
                                        fillColor: '#eeeeee'
                                    }
                                }
                            };

                            const pdfDocGenerator = pdfMake.createPdf(gerar);

                            pdfDocGenerator.getBlob((blob) => {
                                const storageRef = ref(storage, `pdfs/${id}`);
                                uploadBytes(storageRef, blob).then((snapshot) => {
                                    console.log('Arquivo criado');
                                    getDownloadURL(ref(storage, `pdfs/${id}`))
                                        .then((url) => {
                                            
                                            const dados = JSON.stringify({
                                                email: emailTec,
                                                nome: nomeTec,
                                                fileUrl: url,
                                                nomeFile: `${planoGerenciamento} - ${razaoSocial} - ${dataArquivo}.pdf`
                                            });

                                            const customConfig = {headers:{'Content-Type': 'application/json'}};
            
                                            axios.post(SERVER_EMAIL + 'sendEmail', dados, customConfig)
                                                .then(() => {
            
                                                    console.log('E-mail enviado!');

                                                    toast.success('E-mail enviado com Sucesso!', {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "colored",
                                                    });
            
                                                    // Atualizar status do regitro
                                                    updateDoc(path, {
                                                        status: true,
                                                        dataEnvio: new Date().toLocaleDateString(),
                                                        horarioEnvio: new Date().toLocaleTimeString(),
                                                    });

                                                    // Deletar pdf
                                                    deleteObject(ref(storage, `pdfs/${id}`))
                                                        .then(() => {
                                                            console.log('Arquivo deletado');
                                                        })
                                                })
                                                .catch(() => {
                                                    console.log('Erro ao enviar e-mail');
                                                    toast.error('Erro ao enviao o e-mail!', {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "colored",
                                                    });
                                                })

                                        })
                                    
                                })
                            })

                        })
                })
                .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
}

export default geradorPdfPgrs;
