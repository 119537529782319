import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import QRCodeLink from "qrcode";
import { ASSINATURA_CANETA, LOGO_CABECARIO, LOGO_TRANSPARENTE, URL_PADRAO } from "../padrao";

function verPdfPgrccCnpj(id) {

    QRCodeLink.toDataURL(`${URL_PADRAO}protocolo/${id}`, {
        width: 100,
        margin: 1
    })
        .then((response) => {
 
            // QRCode em base64
            const qrcodePDF = response;

            // Pasta registro db
            var path = doc(db, 'registros', id);
            getDoc(path)
                .then((response) => {

                    // Pasta empresa db
                    var pathE = doc(db, 'empresa', 'S7gh2YZKeCMymBcBeCor');
                    getDoc(pathE)
                        .then((data) => {

                            const matrizE = data.data().matriz.endereco;
                            const matrizT = data.data().matriz.fone;
                            const ball = data.data().barracoes;

                            const celShow = (str) => str.length > 10 ? str.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4") : str.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
                            
                            const dataArquivo = () => {
                                if(response.data().dataEnvio == undefined || response.data().dataEnvio == '') {
                                    return response.data().data;
                                } else {
                                    return response.data().dataEnvio;
                                }
                            }

                            const horarioArquivo = () => {
                                if(response.data().horarioEnvio == undefined || response.data().horarioEnvio == '') {
                                    return response.data().horario;
                                } else {
                                    return response.data().horarioEnvio;
                                }
                            }

                            const metrosQuadrados = response.data().obra.metrosQuadrados + 'm²';
                            const ruaObra = response.data().obra.endereco.rua.toUpperCase();
                            const numeroObra = response.data().obra.endereco.numero;
                            const bairroObra = response.data().obra.endereco.bairro.toUpperCase();
                            const cidadeObra = response.data().obra.endereco.cidade.split('-');

                            const nomeRe = response.data().representante.nome.toUpperCase();
                            const cnpjRe = response.data().representante.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                            const telefoneRe = celShow(response.data().representante.telefone);
                            const emailRe = response.data().representante.email.toUpperCase();

                            const nomeTec = response.data().tecnico.nome.toUpperCase();
                            const registro = response.data().tecnico.registro !== '' ? response.data().tecnico.registro.toUpperCase() : 'Não possui registro';
                            const emailTec = response.data().tecnico.email.toUpperCase();
                            const telefoneTec = celShow(response.data().tecnico.telefone);

                            const papel = parseFloat(response.data().residuos.papel);
                            const plastico = parseFloat(response.data().residuos.plastico);
                            const metal = parseFloat(response.data().residuos.metal);
                            const vidro = parseFloat(response.data().residuos.vidro);

                            const totalDecimal = (el) => parseFloat(el) > 0 && parseFloat(el) < 1 ? 1 : (el).toFixed(2);
                            const total = totalDecimal((papel + metal + plastico + vidro));

                            // data
                            function dataPdf() {
                                function formatarData(str) {
                                    var partes = str.split('/').map(Number);
                                    var data = new Date(partes[2], partes[1] - 1, partes[0]);
                                    return data.toLocaleString([], { year: 'numeric', month: 'long', day: 'numeric' });
                                }
                                return formatarData(dataArquivo())
                            }

                            // Capitalize
                            function capitalizeFirst(str) {
                                var subst = str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
                                return subst;
                            }

                            pdfMake.vfs = pdfFonts.pdfMake.vfs;

                            // Fonts
                            pdfMake.fonts = {
                                Roboto: {
                                    normal: URL_PADRAO + 'fonts/Roboto-Regular.ttf',
                                    bold: URL_PADRAO + 'fonts/Roboto-Bold.ttf',
                                    italics: URL_PADRAO + 'fonts/Roboto-Italic.ttf',
                                    bolditalics: URL_PADRAO + 'fonts/Roboto-BoldItalic.ttf'
                                },
                            }

                            const headerPdf = [
                                {
                                    image: LOGO_CABECARIO,
                                    width: 200,
                                    alignment: 'center',
                                    margin: [0, 20, 0, 0]
                                },
                                {
                                    text: ['Cooperativa dos Agentes Ambientais de Foz do Iguaçu \n CNPJ – 04.912.806/0001-07'],
                                    fontSize: 10,
                                    bold: true,
                                    italics: true,
                                    color: '#306868',
                                    alignment: 'center',
                                    margin: [0, 5, 0, 0]
                                },
                            ]

                            const mainPdf = [
                                {
                                    text: ['TERMO DE COMPROMISSO COAAFI – OBRAS'],
                                    fontSize: 14,
                                    bold: true,
                                    alignment: 'center',
                                    margin: [0, 5, 0, 0]
                                },
                                {
                                    text: [
                                        'O presente Termo de Compromisso, entre a Cooperativa dos Agentes Ambientais de Foz do Iguaçu – COAAFI, entidade devidamente constituída, inscrita no CNPJ sob n° 04.912.806/0001-07, sito com sua matriz na ' + matrizE +', aqui representada por', { text: 'CLEUSA CORDEIRO DA SILVA,', bold: true }, ' Rg: 7.095.529-6, CPF: 015.087.199-69, Cargo: Presidente, ora denominada como ', { text: 'Unidade Receptora,', bold: true, decoration: 'underline' }, ' e a obra de ', { text: metrosQuadrados, bold: true }, ', localizada na ', { text: ruaObra, bold: true }, ', n°: ', { text: numeroObra, bold: true }, ', Bairro: ', { text: bairroObra, bold: true }, ', na cidade de ', { text: cidadeObra[0].toUpperCase(), bold: true }, ', aqui representada por ', { text: nomeRe, bold: true }, ', Proprietário, CNPJ: ', { text: cnpjRe, bold: true }, ', telefone: ', { text: telefoneRe, bold: true }, ', e-mail:', { text: emailRe, decoration: 'underline', link: 'mailto:' + emailRe, color: '#0000FF' }, ', ora denominada de ', { text: 'Unidade Geradora.', bold: true, decoration: 'underline' }, ' O responsável pelo PGRCC ', { text: nomeTec, bold: true }, ', Registro: ', { text: registro, bold: true }, ', e-mail: ', { text: emailTec, decoration: 'underline', link: 'mailto:' + emailTec, color: '#0000FF' }, ', telefone: ', { text: telefoneTec, bold: true }, ', ora denominado Responsável Técnico. O presente termo tem por objetivo, regularizar a doação dos Resíduos Sólidos Recicláveis, produzidos pela Unidade Geradora, em conformidade com o Plano de Gerenciamento de Resíduos Sólidos da Construção Civil – PGRCC e o Plano de Gerenciamento de Resíduos Sólidos da Demolição - PGRCD, estabelecido pela Secretaria Municipal de Meio Ambiente e Secretaria Municipal de Obras pelo Decreto nº: 28.368/2020. \n\n O presente Termo de Compromisso possui as seguintes cláusulas:'
                                    ],
                                    style: 'main'
                                },
                                {
                                    type: 'lower-alpha',
                                    separator: ')',
                                    ol: [
                                        {
                                            text: [
                                                'O ', { text: 'Responsável Técnico', bold: true }, ' se compromete em prover a capacitação e o treinamento inicial da Unidade Geradora, e de forma continuada acompanhar e orientar a segregação básica, manejo e armazenamento temporário dos resíduos sólidos passíveis de reciclagem.\n\n'
                                            ]
                                        },

                                        {
                                            text: [
                                                'A ', { text: 'Unidade Geradora', bold: true }, '  se compromete a informar a unidade receptora a data de início da obra e realizar a doação dos resíduos sólidos, passíveis de reciclagem. Abaixo identificado conforme Resolução CONAMA nº 307/2002:\n\n'
                                            ]
                                        },
                                        [
                                            {
                                                text: [
                                                    'A ', { text: 'Unidade Geradora', bold: true }, ' se compromete a realizar a doação dos resíduos sólidos, passíveis de reciclagem abaixo identificado:\n\n'
                                                ]
                                            },
                                            {
                                                table: {
                                                    widths: [200, 200],
                                                    body: [
                                                        [{ text: 'INDENTIFICAÇÃO QUANTITATIVA', fillColor: '#306868', color: '#fff', alignment: 'center', bold: true, fontSize: 14, colSpan: 2 }, {}],
                                                        [{ text: 'RESÍDUO CLASSE', style: 'tableTitle' }, { text: 'QUANTIDADE (Kg)', style: 'tableTitle' }],
                                                        [{ text: 'Papel', alignment: 'center' }, { text: papel, alignment: 'center' }],
                                                        [{ text: 'Plástico', alignment: 'center' }, { text: plastico, alignment: 'center' }],
                                                        [{ text: 'Metal', alignment: 'center' }, { text: metal, alignment: 'center' },],
                                                        [{ text: 'Vidro', alignment: 'center' }, { text: vidro, alignment: 'center' }],
                                                        [{ text: 'TOTAL', style: 'tableTitle' }, { text: total, alignment: 'center', bold: true }],
                                                    ],
                                                },
                                                margin: [30, 0, 0, 20]
                                            }
                                        ],
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Geradora', bold: true }, ' se compromete a se cadastra no sistema ', { text: 'SINIR', bold: true }, ' – Sistema Nacional de Informações sobre a gestão dos Resíduos Sólidos e Emitir o ', { text: 'MTR', bold: true }, ' – Manifesto de Transporte de Resíduos conforme a Portaria nº 280, de 29 de junho de 2020, emitida pelo Ministério do Meio Ambiente, a utilização do MTR online é obrigatória em todo o território brasileiro a partir de 1º de janeiro de 2021.\n\n'
                                            ]
                                        },
                                        [
                                            {
                                                text: [
                                                    'A ', { text: 'Unidade Receptora', bold: true }, ' através de seu(s) cooperado(s), se compromete(m) a ', { text: 'receber', bold: true }, ' nos 7 (Sete) Centros de Triagens na cidade de Foz do Iguaçu resíduos sólidos que tenha como destino o processo de reciclagem, a coleta no local da unidade geradora poderá ser realiza conforme a logística de coleta da cooperativa e mediante articulação previa com o responsável legal.\n\n\n\n\n\n\n\n\n\n\n\n'
                                                ]
                                            },
                                            {
                                                table: {
                                                    headerRows: 1,
                                                    body: [
                                                        [{ text: 'ENDEREÇOS DOS BARRACÕES', fillColor: '#306868', color: '#fff', alignment: 'center', bold: true, fontSize: 14, colSpan: 2 }, {}],
                                                        [{ text: 'BARRACÕES', style: 'tableTitle' }, { text: 'ENDEREÇO', style: 'tableTitle' }],
                                                        ball.b1.status === true ? [{ text: ball.b1.bairro }, { text: ball.b1.endereco }] : [{text: '--'},{text: '--'}],                                                   
                                                        ball.b2.status === true ? [{ text: ball.b2.bairro }, { text: ball.b2.endereco }] : [{text: '--'},{text: '--'}],
                                                        ball.b3.status === true ? [{ text: ball.b3.bairro }, { text: ball.b3.endereco }] : [{text: '--'},{text: '--'}],
                                                        ball.b4.status === true ? [{ text: ball.b4.bairro }, { text: ball.b4.endereco }] : [{text: '--'},{text: '--'}],
                                                        ball.b5.status === true ? [{ text: ball.b5.bairro }, { text: ball.b5.endereco }] : [{text: '--'},{text: '--'}],
                                                        ball.b6.status === true ? [{ text: ball.b6.bairro }, { text: ball.b6.endereco }] : [{text: '--'},{text: '--'}],
                                                        ball.b7.status === true ? [{ text: ball.b7.bairro }, { text: ball.b7.endereco }] : [{text: '--'},{text: '--'}],
                                                    ],
                                                },
                                                margin: [0, 0, 0, 30]
                                            }
                                        ],
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Receptora', bold: true }, ' se compromete a emitir o Certificado de Destinação Final – CDF conforme o MTR emitido pelo sistema SINER \n\n'
                                            ]
                                        },
                                        {
                                            text: [
                                                'A ', { text: 'Unidade Receptora', bold: true }, ' se compromete em acompanhar os trabalhos do cooperado(s) bem como proceder a indicação de outros, caso o mesmo não cumpra o acordo aqui firmado.\n'
                                            ]
                                        },

                                    ],
                                    style: 'main'
                                },
                                {
                                    text: ['\n\n\n\n\n\n\n\n\n\nPor estarem de acordo, o presente Termo de Compromisso será assinado por todas as partes.\n\n'],
                                    style: 'main'
                                },
                                {
                                    text: ['Foz do Iguaçu, ' + dataPdf()],
                                    alignment: 'right'
                                },
                                {
                                    text: [
                                        '\n\n\n\n________________________________________________________\n',
                                        { text: 'Responsável Técnico –', bold: true }, '\n' + capitalizeFirst(nomeTec)
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: [
                                        '\n\n\n\n\n\n\n________________________________________________________\n',
                                        { text: 'Unidade Geradora –\n' + nomeRe, bold: true }, '\n\n\n\n\n\n\n'
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    image: ASSINATURA_CANETA,
                                    width: 180,
                                    alignment: 'center',
                                    margin: [0, 0, 60, -60]
                                },
                                {
                                    image: LOGO_TRANSPARENTE,
                                    width: 100,
                                    alignment: 'right',
                                    margin: [0, 0, 50, 0]
                                },
                                {
                                    columns: [
                                        {
                                            width: 80,
                                            text: [
                                                'CLEUSA CORDEIRO DA SILVA'
                                            ],
                                            alignment: 'center',
                                            bold: true
                                        },
                                        {
                                            width: 80,
                                            text: [
                                                'Assinado Digitalmente por Cleusa Cordeiro da Silva Email: ', { text: 'coaafi.termo@gmail.com', decoration: 'underline', link: 'mailto:coaafi.termo@gmail.com', color: '#0000FF' }, ' Data: ', dataArquivo() + ' ' + horarioArquivo(), ' Protocolo: ', id
                                            ],
                                            fontSize: 6,
                                            alignment: 'justify'
                                        },
                                        {
                                            width: 45,
                                            image: qrcodePDF,
                                        }
                                    ],
                                    columnGap: 5,
                                    margin: [255, -30, 0, 0],
                                },
                                {
                                    text: [
                                        '________________________________________________________\n',
                                        { text: 'Unidade Receptora – ', bold: true }, 'COAAFI\n Cleusa Cordeiro da Silva \n ', { text: 'Presidente', bold: true }
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: ['\n\nO presente termo tem como validade o período de um ano, a partir da assinatura da unidade receptora'],
                                    style: 'main'
                                }

                            ]

                            function Rodape(currentPage, pageCount) {
                                return {
                                    text: [
                                        matrizE + ' – Foz do Iguaçu – Paraná\n',
                                        'E-mail: coaafi.termo@gmail.com – Fone: ' + celShow(matrizT) + ' \n',
                                        'Página ' + currentPage + ' de ' + pageCount,
                                        {
                                            text: '\nAssinado Digitalmente Data: ' + dataArquivo() + ' \nProtocolo: ' + id,
                                            fontSize: 6,
                                            bold: false,
                                            italics: false,
                                            color: '#000',
                                            alignment: 'right',
                                            margin: [50, 0, 0, 0]
                                        }
                                    ],
                                    fontSize: 10,
                                    bold: true,
                                    italics: true,
                                    color: '#306868',
                                    alignment: 'center',
                                    margin: [15, 15, 15, 30],
                                }
                            }

                            const gerar = {
                                pageSize: 'A4',
                                pageMargins: [50, 110, 50, 70],
                                header: [headerPdf],
                                content: [mainPdf],
                                footer: Rodape,
                                defaultStyle: { font: 'Roboto' },
                                styles: {
                                    main: {
                                        fontSize: 12,
                                        alignment: 'justify',
                                        lineHeight: 1.5,
                                        margin: [0, 20, 0, 0]
                                    },
                                    tableTitle: {
                                        bold: true,
                                        alignment: 'center',
                                        fillColor: '#eeeeee'
                                    }
                                }
                            };

                            if (navigator.userAgent.match(/Android/i)
                                || navigator.userAgent.match(/webOS/i)
                                || navigator.userAgent.match(/iPhone/i)
                                || navigator.userAgent.match(/iPad/i)
                                || navigator.userAgent.match(/iPod/i)
                                || navigator.userAgent.match(/BlackBerry/i)
                                || navigator.userAgent.match(/Windows Phone/i)
                            ) {
                                pdfMake.createPdf(gerar).download();
                            } else {
                                pdfMake.createPdf(gerar).open();
                            }

                        })
                })
                .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
}

export default verPdfPgrccCnpj;
