import React, { useEffect, useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { CELULAR, CEP, CPF } from "../Mascaras";
import { ToastContainer, toast } from 'react-toastify';
import geradorPdfPgrccCpf from "../services/geradorPdfPgrccCpf";
import Header from '../Header';
import AsideCep from "../components/AsideCep";
import BotaoWpp from "../components/BotaoWpp";

export function PgrccCpf() {

    // METROS
    const [metros, setMetros] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');

    // RESPONSÁVEL
    const [cpf, setCpf] = useState('');
    const [nomeR, setNomeR] = useState('');
    const [celularR, setCelularR] = useState('');
    const [emailR, setEmailR] = useState('');

    // TÉCNICO
    const [nomeT, setNomeT] = useState('');
    const [celularT, setCelularT] = useState('');
    const [emailT, setEmailT] = useState('');

    // RESÍDUOS
    const [papel, setPapel] = useState('');
    const [plastico, setPlastico] = useState('');
    const [vidro, setVidro] = useState('');
    const [metal, setMetal] = useState('');

    // FUNÇÕES
    const apenasNumeros = (str) => str.replace(/[^0-9]/g, '');
    const celShow = (str) => str.length > 10 ? str.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4") : str.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    const cpfShow = (str) => str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    const subLimit = (string) => string.length > 20 ? string = string.substring(0, 20) + '...' : string;
    const numeroDecimal = (el) => el == '' ? 0 : parseFloat(el);

    // REGISTRAR
    const createRegistro = async () => {
        let d = new Date().toLocaleDateString();
        var h = new Date().toLocaleTimeString();
        d = d.replaceAll('/', '');
        h = h.replaceAll(':', '');
        var numeroProtocolo = `${d}${h}`;

        await setDoc(doc(db, 'registros', numeroProtocolo), {
            status: false,
            data: new Date().toLocaleDateString(),
            horario: new Date().toLocaleTimeString(),
            paraGrafico: `${new Date().getMonth() + 1}/${new Date().getYear()}`,
            planoGerenciamento: 'PGRCC(CPF)',
            representante: {
                cpf: cpf,
                nome: nomeR,
                email: emailR,
                telefone: celularR
            },
            tecnico: {
                nome: nomeT,
                registro: document.querySelector('#registro-pg').value,
                telefone: celularT,
                email: emailT
            },
            residuos: {
                papel: numeroDecimal(papel) ,
                plastico: numeroDecimal(plastico),
                vidro: numeroDecimal(vidro),
                metal: numeroDecimal(metal)
            },
            obra: {
                metrosQuadrados: apenasNumeros(metros),
                cep: cep,
                endereco: {
                    rua: document.querySelector('#rua-cep-obra').value,
                    bairro: document.querySelector('#bairro-cep-obra').value,
                    numero: apenasNumeros(document.querySelector('#numero-cep-obra').value),
                    cidade: document.querySelector('#cidade-cep-obra').value
                }
            }
        })
            .then(() => {
                document.querySelector('.container-formulario').innerHTML = `
                    <div class="text-center">
                        <h5 class="mb-3">Gerando informações de pagamento..</h5>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                `;

                geradorPdfPgrccCpf(numeroProtocolo);

                setTimeout(() => {
                    window.location.href = '/pagamento/coaafi/';
                }, 2000);

            })
            .catch((error) => {
                toast.info('Erro ao enviar o formulário! Tente novamente', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(error);
            })

        setTimeout(() => {
            geradorPdfPgrccCpf(numeroProtocolo);
        }, 1000);
    };

    // CEP
    useEffect(() => {
        if (cep.length === 8) {
            async function reqCep() {
                await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.erro) {
                            setRua(data.logradouro);
                            document.querySelector('.campos-cep-result').innerHTML = '';
                            let campoCepObra = document.querySelector('.campos-cep-obra').cloneNode(true);
                            campoCepObra.querySelector('#rua-cep-obra').value = data.logradouro;
                            campoCepObra.querySelector('#bairro-cep-obra').value = data.bairro;
                            campoCepObra.querySelector('#numero-cep-obra').value = '';
                            campoCepObra.querySelector('#cidade-cep-obra').value = `${data.localidade} - ${data.uf}`;
                            document.querySelector('.campos-cep-result').append(campoCepObra);
                        } else {
                            toast.error('Esse CEP não existe! Confira os números e tente novamente!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log('Erro:', error);
                    });
            }
            setTimeout(() => {
                reqCep();
            }, 300)
        } else {
            document.querySelector('.campos-cep-result').innerHTML = '';
        }
    }, [cep])

    // VERIFIC CAMPOS
    useEffect(() => {
        if (nomeT !== '' && celularT !== '' && emailT !== '') {
            document.querySelector('.botao-enviar').setAttribute('type', 'button');
            document.querySelector('.botao-enviar').setAttribute('data-bs-target', '#exampleModal');
        } else {
            document.querySelector('.botao-enviar').setAttribute('type', 'submit');
            document.querySelector('.botao-enviar').removeAttribute('data-bs-target', '#exampleModal');
        }

    }, [nomeT, celularT, emailT])

    useEffect(() => {
        if(papel !== '' || plastico !== '' || vidro !== '' || metal !== '') {
            document.querySelectorAll('.numeroIn').forEach((n) => n.removeAttribute('required', ''))
        }else {
            document.querySelectorAll('.numeroIn').forEach((u) => u.setAttribute('required', ''))
        }
    }, [papel, plastico, vidro, metal])

    return (
        <div>

            <Header />

            <main className='mb-5 pb-5'>
                <div className="container mt-4 position-relative">
                    <div className="row d-flex justify-content-center align-items-center">

                        <div className="col-md-8 container-formulario">

                            <form>

                                <div id="obra">
                                    <div className="row">
                                        <h1>Obra</h1>
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Metros quadrados (m²)</label>
                                                <input type="number" className="form-control" onChange={e => setMetros(e.target.value)} placeholder="m²" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">CEP</label>
                                                <CEP
                                                    value={cep}
                                                    onChange={(event) => setCep(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="campos-cep-result"></div>
                                </div>

                                <div id="representante">
                                    <h1 className="mt-4">Pessoa Representante</h1>
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">CPF</label>
                                                <CPF
                                                    value={cpf}
                                                    onChange={(event) => setCpf(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Nome</label>
                                                <input type="text" className="form-control" id="nome-re" onChange={e => setNomeR(e.target.value)} required placeholder="nome" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Telefone/Celular (com DDD)</label>
                                                <CELULAR
                                                    value={celularR}
                                                    onChange={(event) => setCelularR(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">E-mail</label>
                                                <input type="email" className="form-control" id="email-re" onChange={e => setEmailR(e.target.value)} required placeholder="e-mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="tecnico">
                                    <h1 className="mt-5">Técnico Responsável</h1>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="mb-3">
                                                <label className="form-label">Nome</label>
                                                <input type="text" className="form-control" id="nome-pg" onChange={e => setNomeT(e.target.value)} required placeholder="nome" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label className="form-label">Registro</label>
                                                <input type="text" className="form-control" id="registro-pg" placeholder="registro" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Telefone/Celular (com DDD)</label>
                                                <CELULAR
                                                    value={celularT}
                                                    onChange={(event) => setCelularT(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="mb-3">
                                                <label className="form-label">E-mail</label>
                                                <input type="email" className="form-control" id="email-pg" onChange={e => setEmailT(e.target.value)} required placeholder="e-mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="residuos">
                                    <h1 className="mt-5">Resíduos</h1>
                                    <div className="row">
                                        <div className="col-md-3 col-6">
                                            <div className="mb-3">
                                                <label className="form-label">Papel</label>
                                                <input type="number" className="form-control numeroIn" onChange={e => setPapel(e.target.value)} placeholder="kg"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="mb-3">
                                                <label className="form-label">Plástico</label>
                                                <input type="number" className="form-control numeroIn" onChange={e => setPlastico(e.target.value)} placeholder="kg"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="mb-3">
                                                <label className="form-label">Vidro</label>
                                                <input type="number" className="form-control numeroIn" onChange={e => setVidro(e.target.value)} placeholder="kg" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="mb-3">
                                                <label className="form-label">Metal</label>
                                                <input type="number" className="form-control numeroIn" onChange={e => setMetal(e.target.value)} placeholder="kg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary botao-enviar mt-3" data-bs-toggle="modal">Enviar</button>

                                <div id="modall">
                                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Confirmação dos Dados</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">

                                                    <p className="text-center fw-bold mt-2 mb-0 bg-dark text-white py-1">OBRA</p>
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>Metros quadrados (m²)</th>
                                                                <td>{metros + 'm²'}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Endereço</th>
                                                                <td>{subLimit(rua.toUpperCase())}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <p className="text-center fw-bold mt-4 mb-0 bg-dark text-white py-1">EMPRESA</p>
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>Pessoa Representante</th>
                                                                <td>{cpfShow(cpf)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <td>{subLimit(nomeR.toLocaleUpperCase())}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <p className="text-center fw-bold mt-4 mb-0 bg-dark text-white py-1">TÉCNICO</p>
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <td>{nomeT.toUpperCase()}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Telefone</th>
                                                                <td>{celShow(celularT)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Email</th>
                                                                <td>{emailT.toUpperCase()}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Editar</button>
                                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={createRegistro}>Enviar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <ToastContainer />
                    <AsideCep />

                    <div className="position-fixed wpp-fixed">
                        <BotaoWpp />
                    </div>

                </div>
            </main>
        </div>
    );
}
