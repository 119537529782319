function AsideCpnj() {
    return (
        <aside className="d-none">
            <div className="campos-cnpj">
                <div className="mb-3">
                    <label className="form-label">Razão Social</label>
                    <input type="text" className="form-control" id="razaoSocial" required />
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="mb-3">
                            <label className="form-label">Nome da fantasia</label>
                            <input type="text" className="form-control" id="nomeFansia" required/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-3">
                            <label className="form-label">Cidade</label>
                            <input type="text" className="form-control" id="cidadeEstado" required/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-7">
                        <div className="mb-3">
                            <label className="form-label">Endereço</label>
                            <input type="text" className="form-control" id="endereco" required/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="mb-3">
                            <label className="form-label">Número</label>
                            <input type="text" className="form-control" id="numero" required/>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="mb-3">
                            <label className="form-label">CEP</label>
                            <input type="text" className="form-control" id="cep" required/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-3">
                            <label className="form-label">E-mail</label>
                            <input type="email" className="form-control" id="email" required/>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-3">
                            <label className="form-label">Telefone/Celular</label>
                            <input type="text" className="form-control" id="telefone" required/>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Nome Sócio</label>
                    <input type="text" className="form-control" id="nomeSocio" required/>
                </div>
            </div>
        </aside>
    );
}

export default AsideCpnj;
