import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import HeaderPainel from "../components/HeaderPainel";
import GraficoPainel from "../components/GraficoPainel";
import axios from "axios";
import { SERVER_EMAIL } from "../padrao";


export function Painel() {

    const [registros, setRegristros] = useState([]);

    useEffect(() => {
        const pathDB = query(collection(db, 'registros'));
        onSnapshot(pathDB, (querySnapshot) => {
            setRegristros(querySnapshot.docs.map(doc => ({
                data: doc.data(),
                id: doc.id
            })))
        });
    }, []);

    function verificReg(el) {
        if (el > 0) {
            document.querySelector('#pendentes').classList.remove('bg-dark');
            document.querySelector('#pendentes').classList.add('bg-danger');
        }
        return el;
    }

    // Link pendendes
    function urlPendentes() {
        if (registros.filter(a => a?.data?.status === false).length > 0) {
            window.location.href = '/painel/pendentes';
        } else {
            return null;
        }
    }

    // verificação de registros vencidos
    const verificaRegistrosVencidos = () => {

        const dataAtual = new Date().toLocaleDateString();

        // Envio de email um 30 dias antes de vencer
        registros.filter(a => a?.data?.vencimento?.mensagemOnzeMeses === false).map(function (e, index) {
            if (e?.data?.vencimento?.dataOnzeMeses === dataAtual) {
                var path = doc(db, 'registros', e?.id);

                const dados = JSON.stringify({
                    email: e?.data?.tecnico.email,
                    nome: e?.data?.tecnico.nome,
                    razaoSocial: e?.data?.empresa?.razaoSocial,
                    dataTermo: e?.data?.data,
                });

                const customConfig = { headers: { 'Content-Type': 'application/json' } };
                axios.post(SERVER_EMAIL + 'sendEmailMes', dados, customConfig)
                    .then(() => console.log('Enviado com sucesso!'))
                    .catch(() => console.log('Erro ao enviar o E-mail'))

                // Atualizar regitro
                updateDoc(path,
                    {
                        vencimento: {
                            dataOnzeMeses: e?.data?.vencimento?.dataOnzeMeses,
                            mensagemOnzeMeses: true,
                            dataUmdia: e?.data?.vencimento?.dataUmdia,
                            mensagemUmdia: e?.data?.vencimento?.mensagemUmdia,
                        }
                    }
                );
            }
        });

        // Envio de email um 1 dia antes de vencer
        registros.filter(a => a?.data?.vencimento?.mensagemUmdia === false).map(function (e, index) {
            if (e?.data?.vencimento?.mensagemUmdia === dataAtual) {

                var path = doc(db, 'registros', e?.id);
                const dados = JSON.stringify({
                    email: e?.data?.tecnico.email,
                    nome: e?.data?.tecnico.nome,
                    razaoSocial: e?.data.empresa?.razaoSocial,
                    dataTermo: e?.data?.data,
                });

                const customConfig = { headers: { 'Content-Type': 'application/json' } };
                axios.post(SERVER_EMAIL + 'sendEmailDia', dados, customConfig)
                    .then(() => console.log('Enviado com sucesso!'))
                    .catch(() => console.log('Erro ao enviar o E-mail'))

                // Excluir registro
                deleteDoc(path)
                    .then(() => console.log('Registro deletado!'))

            }
        })
    }

    return (
        <div>

            <HeaderPainel />

            <main className='mb-5 pb-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-md mb-3">
                            <div id="pendentes" onClick={urlPendentes} className="rounded box-painel text-center bg-dark bg-gradient text-white p-md-4 px-1 py-2">
                                <h2>Pendentes</h2>
                                <h2>
                                    {verificReg(registros.filter(a => a?.data?.status === false).length)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-6 col-md mb-3">
                            <a href="/painel/enviados">
                                <div className="rounded box-painel text-center bg-success bg-gradient text-white p-md-4 px-1 py-2">
                                    <h2>Enviados</h2>
                                    <h2>{registros.filter(a => a?.data?.status === true).length}</h2>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md mb-3">
                            <a href="/painel/pgrs">
                                <div className="rounded box-painel text-center bg-success bg-gradient text-white p-md-4 px-1 py-2">
                                    <h2>PGRS</h2>
                                    <h2>{registros.filter(a => a?.data?.planoGerenciamento === "PGRS").length}</h2>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md mb-3">
                            <a href="/painel/pgrcc">
                                <div className="rounded box-painel text-center bg-success bg-gradient text-white p-md-4 px-1 py-2">
                                    <h2>PGRCC</h2>
                                    <h2>{registros.filter(a => a?.data?.planoGerenciamento === "PGRCC(CNPJ)").length + registros.filter(a => a?.data?.planoGerenciamento === "PGRCC(CPF)").length}</h2>
                                </div>
                            </a>
                        </div>
                        <div className="col mb-3">
                            <div className="rounded box-painel text-center bg-success bg-gradient text-white p-md-4 px-1 py-2">
                                <h2>Total</h2>
                                <h2>{registros.length}</h2>
                            </div>
                        </div>
                    </div>

                    <GraficoPainel />

                </div>
            </main>
            {verificaRegistrosVencidos()}
        </div>
    );

}
