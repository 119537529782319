import { useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Header from "../Header";
import { Navigate } from "react-router-dom";
const provider = new GoogleAuthProvider();

export function Login() {
    const [user, setUser] = useState('');
    const auth = getAuth();

    const loginGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                if (user.email === "ozzeeh@gmail.com" || user.email === "coaafi.termo@gmail.com") {
                    setUser(user);
                    sessionStorage.setItem("@AuthFirebase:token", token);
                    sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
                    return <Navigate to="/painel" />
                } else {
                    return <Navigate to="/" />
                }

            })
            .catch((error) => console.log(error));
    }

    const sessionToken = sessionStorage.getItem("@AuthFirebase:token");
    const sessionUser = sessionStorage.getItem("@AuthFirebase:user");

    if(!sessionToken || !sessionUser) {
        return (
            <div>
                <Header />
                <div className="d-flex justify-content-center mt-5">
                    <button className="login-with-google-btn" onClick={loginGoogle}>Login com o Google</button>
                </div>
            </div>
        );
    } else {
        return <Navigate to="/painel" />
    }

    
}
