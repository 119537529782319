import React, { useEffect, useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { CNPJ, CELULAR } from "../Mascaras";
import { ToastContainer, toast } from 'react-toastify';
import geradorPdfPgrs from "../services/geradorPdfPgrs";
import Header from '../Header';
import AsideCnpj from '../components/AsideCnpj';
import BotaoWpp from "../components/BotaoWpp";

export function Pgrs() {

    // EMPRESA
    const [cnpj, setCnpj] = useState('');
    const [razao, setRazao] = useState('');

    // TÉCNICO
    const [nomeT, setNomeT] = useState('');
    const [celularT, setCelularT] = useState('');
    const [emailT, setEmailT] = useState('');

    // RESÍDUOS
    const [papel, setPapel] = useState('');
    const [plastico, setPlastico] = useState('');
    const [vidro, setVidro] = useState('');
    const [metal, setMetal] = useState('');

    useEffect(() => {
        if (cnpj.length >= 14) {
            async function req() {
                await fetch('https://publica.cnpj.ws/cnpj/' + cnpj)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.razao_social !== undefined) {
                            document.querySelector('.cnpj-result').innerHTML = '';
                            let campoCnpj = document.querySelector('.campos-cnpj').cloneNode(true);
                            campoCnpj.querySelector('#razaoSocial').value = data.razao_social;
                            setRazao(data.razao_social);
                            campoCnpj.querySelector('#nomeFansia').value = data.estabelecimento.nome_fantasia;
                            campoCnpj.querySelector('#cidadeEstado').value = `${data.estabelecimento.cidade.nome} - ${data.estabelecimento.estado.nome} - ${data.estabelecimento.pais.iso2}`;
                            campoCnpj.querySelector('#endereco').value = `${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}, ${data.estabelecimento.bairro}`;
                            campoCnpj.querySelector('#numero').value = data.estabelecimento.numero;
                            campoCnpj.querySelector('#cep').value = cepShow(data.estabelecimento.cep);
                            campoCnpj.querySelector('#email').value = data.estabelecimento.email;
                            campoCnpj.querySelector('#telefone').value = celShow(verificNull(data.estabelecimento.ddd1) + verificNull(data.estabelecimento.telefone1));
                            campoCnpj.querySelector('#nomeSocio').value = data.socios.length > 0 ? data.socios[0].nome : '';
                            document.querySelector('.cnpj-result').append(campoCnpj);
                        } else {
                            toast.error('Esse CNPJ não existe! Confira os números e tente novamente!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                    })
                    .catch((error) => {
                        toast.error('Erro ao consultar CNPJ! Confira os números e tente novamente!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        console.log('Erro:', error);
                    });
            }
            setTimeout(() => {
                req();
            }, 500)
        } else {
            document.querySelector('.cnpj-result').innerHTML = '';
        }
    }, [cnpj])

    const apenasNumeros = (str) => str.replace(/[^0-9]/g, '');
    const cepShow = (str) => str.replace(/^(\d{5})(\d{3})/, "$1-$2");
    const celShow = (str) => str.length > 10 ? str.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4") : str.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    const cnpjShow = (str) => str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    const subLimit = (string) => string.length > 20 ? string = string.substring(0, 20) + '...' : string;
    const verificNull = (el) => el != null ? el : '';
    const numeroDecimal = (el) => el == '' ? 0 : parseFloat(el);

    useEffect(() => {
        if (cnpj !== '' && nomeT !== '' && celularT !== '' && emailT !== '' && razao !== '') {
            document.querySelector('.botao-enviar').setAttribute('type', 'button');
            document.querySelector('.botao-enviar').setAttribute('data-bs-target', '#exampleModal');
        } else {
            document.querySelector('.botao-enviar').setAttribute('type', 'submit');
            document.querySelector('.botao-enviar').removeAttribute('data-bs-target', '#exampleModal');
        }
    }, [nomeT, cnpj, celularT, emailT])

    useEffect(() => {
        if(papel !== '' || plastico !== '' || vidro !== '' || metal !== '') {
            document.querySelectorAll('.numeroIn').forEach((n) => n.removeAttribute('required', ''))
        }else {
            document.querySelectorAll('.numeroIn').forEach((u) => u.setAttribute('required', ''))
        }
    }, [papel, plastico, vidro, metal])


    const createRegistro = async () => {

        // Definir número protocolo
        let d = new Date().toLocaleDateString();
        var h = new Date().toLocaleTimeString();
        d = d.replaceAll('/', '');
        h = h.replaceAll(':', '');
        var numeroProtocolo = `${d}${h}`;

        // Verifica o dia da semana Onze Meses
        const verificationSemanaO = (w) => {
            let m = new String(w);
            if (m[0] === 'S') {
                let semana = new Date(w);
                semana.addDias(-3);
                return semana.toLocaleDateString();
            } else {
                return w.toLocaleDateString();
            }
        }

        // Verifica o dia da semana Um dia
        const verificationSemanaD = (w) => {
            let m = new String(w);
            if (m[0] === 'S') {
                let semana = new Date(w);
                semana.addDias(-4);
                return semana.toLocaleDateString();
            } else {
                let dia = new Date(w);
                dia.addDias(-1);
                return dia.toLocaleDateString();
            }
        }

        // Adiciona meses
        Date.prototype.addMeses = function (meses) {
            this.setMonth(this.getMonth() + meses)
        };

        // Adiciona dias
        Date.prototype.addDias = function (dias) {
            this.setDate(this.getDate() + dias)
        };

        const dataMesOnze = new Date();
        dataMesOnze.addMeses(11);
        const dataDias = new Date();
        dataDias.addMeses(12);
        
        await setDoc(doc(db, 'registros', numeroProtocolo), {
            status: false,
            data: new Date().toLocaleDateString(),
            horario: new Date().toLocaleTimeString(),
            paraGrafico: `${new Date().getMonth() + 1}/${new Date().getYear()}`,
            planoGerenciamento: 'PGRS',
            empresa: {
                cnpj: cnpj,
                razaoSocial: document.querySelector('#razaoSocial').value,
                nomeFantasia: document.querySelector('#nomeFansia').value,
                cidadeEstado: document.querySelector('#cidadeEstado').value,
                endereco: document.querySelector('#endereco').value,
                numero: document.querySelector('#numero').value,
                cep: apenasNumeros(document.querySelector('#cep').value),
                email: document.querySelector('#email').value,
                telefone: apenasNumeros(document.querySelector('#telefone').value),
                socio: document.querySelector('#nomeSocio').value
            },
            tecnico: {
                nome: nomeT,
                registro: document.querySelector('#registro-pg').value,
                telefone: celularT,
                email: emailT
            },
            residuos: {
                papel: numeroDecimal(papel) ,
                plastico: numeroDecimal(plastico),
                vidro: numeroDecimal(vidro),
                metal: numeroDecimal(metal)
            },
            vencimento: {
                dataOnzeMeses: verificationSemanaO(dataMesOnze),
                mensagemOnzeMeses: false,
                dataUmdia: verificationSemanaD(dataDias),
                mensagemUmdia: false,
            }
        })
            .then(() => {
                document.querySelector('.container-formulario').innerHTML = `
                    <div class="text-center">
                        <h5 class="mb-3">Gerando informações de pagamento..</h5>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                `;

                geradorPdfPgrs(numeroProtocolo);

                setTimeout(() => {
                    window.location.href = '/pagamento/coaafi/';
                }, 2000);
            })
            .catch((error) => {
                toast.info('Erro ao enviar o formulário! Tente novamente', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(error);
            })
    };


    return (
        <div>
            <Header />

            <main className='mb-5 pb-5'>
                <div className="container mt-4 position-relative">
                    <div className="row d-flex justify-content-center align-items-center">

                        <div className="col-md-8 container-formulario">

                            <form className="">
                                <h1>Empresa</h1>
                                <div className="mb-3">
                                    <label className="form-label">CNPJ</label>
                                    <CNPJ
                                        value={cnpj}
                                        onChange={(event) => setCnpj(event.target.value)}
                                        required
                                    />
                                </div>
                                <div className="cnpj-result"></div>

                                <h1 className="mt-5">Técnico Responsável</h1>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="mb-3">
                                            <label className="form-label">Nome</label>
                                            <input type="text" className="form-control" id="nome-pg" onChange={e => setNomeT(e.target.value)} required placeholder="nome" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label">Registro</label>
                                            <input type="text" className="form-control" id="registro-pg" placeholder="registro" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Telefone/Celular (com DDD)</label>
                                            <CELULAR
                                                value={celularT}
                                                onChange={(event) => setCelularT(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="mb-3">
                                            <label className="form-label">E-mail</label>
                                            <input type="email" className="form-control" id="email-pg" onChange={e => setEmailT(e.target.value)} required placeholder="e-mail" />
                                        </div>
                                    </div>
                                </div>

                                <h1 className="mt-5">Resíduos</h1>

                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <div className="mb-3">
                                            <label className="form-label">Papel</label>
                                            <input type="number" className="form-control numeroIn" onChange={e => setPapel(e.target.value)} placeholder="kg" required/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="mb-3">
                                            <label className="form-label">Plástico</label>
                                            <input type="number" className="form-control numeroIn" onChange={e => setPlastico(e.target.value)} placeholder="kg" required/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="mb-3">
                                            <label className="form-label">Vidro</label>
                                            <input type="number" className="form-control numeroIn" onChange={e => setVidro(e.target.value)} placeholder="kg" required/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="mb-3">
                                            <label className="form-label">Metal</label>
                                            <input type="number" className="form-control numeroIn" onChange={e => setMetal(e.target.value)} placeholder="kg" required/>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary botao-enviar mt-3" data-bs-toggle="modal">Enviar</button>


                                <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Confirmação dos Dados</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <p className="text-center fw-bold mt-2 mb-0 bg-dark text-white py-1">EMPRESA</p>
                                                <table className="table table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>CNPJ</th>
                                                            <td>{cnpjShow(cnpj)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>RAZÃO SOCIAL</th>
                                                            <td>{subLimit(razao).toUpperCase()}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <p className="text-center fw-bold mt-4 mb-0 bg-dark text-white py-1">TÉCNICO</p>
                                                <table className="table table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>NOME</th>
                                                            <td>{nomeT.toUpperCase()}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>TELEFONE</th>
                                                            <td>{celShow(celularT)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>EMAIL</th>
                                                            <td>{emailT.toUpperCase()}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Editar</button>
                                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={createRegistro}>Enviar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>

                    <ToastContainer />
                    <AsideCnpj />

                    <div className="position-fixed wpp-fixed">
                        <BotaoWpp />
                    </div>

                </div>
            </main>
        </div>
    );
}
