function AsideCep() {
    return (
        <aside className="d-none">
            <div className="campos-cep-obra">
                <div className="row">
                    <div className="col-8">
                        <div className="mb-3">
                            <label className="form-label">Rua</label>
                            <input type="text" className="form-control" id="rua-cep-obra" required />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-3">
                            <label className="form-label">Bairro</label>
                            <input type="text" className="form-control" id="bairro-cep-obra" required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="mb-3">
                            <label className="form-label">Número</label>
                            <input type="text" className="form-control" id="numero-cep-obra" placeholder="número" required />
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="mb-3">
                            <label className="form-label">Cidade/Estado</label>
                            <input type="text" className="form-control" id="cidade-cep-obra" required />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default AsideCep;
