import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { BotaoExcluir, BotaoEnviar, BotaoVisualizar } from "./Botoes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderPainel from "../components/HeaderPainel";

export function TermosPendentes() {
    
    const [registros, setRegristros] = useState([]);

    useEffect(() => {
        const q = query(collection(db, 'registros'));
        onSnapshot(q, (querySnapshot) => {
            setRegristros(querySnapshot.docs.map(doc => ({
                data: doc.data(),
                id: doc.id
            })))
        })

    }, []);

    const subLimit = (string) => string.length > 25 ? string = string.substring(0, 25) + '...' : string;
    const dataFiltro = (data) => {
        let filtro = data.split('/');
        return `${filtro[2]}-${filtro[1]}-${filtro[0]}`;
    }

    return (
        <div>

            <HeaderPainel />

            <main className='mb-5 pb-5'>
                <div className="container mt-4">
                    <div>
                        <p className="d-flex align-items-center">
                            <a href="/painel" className="link-menu d-flex align-items-center me-2">
                                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
                                Início
                            </a> 
                            - Termos Pendentes
                        </p>
                    </div>
                    <div className="d-md-block d-none">
                        <table className="table table-striped table-bordered">
                            <thead className="table-dark bg-gradient">
                                <tr>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Técnico</th>
                                    <th scope="col">Plano de Gerenciamento</th>
                                    <th scope="col">Data e Horário</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    registros.filter(a => a?.data?.status === false).sort((x,y) => Date.parse(dataFiltro(y.data.data)) - Date.parse(dataFiltro(x.data.data))).map(function (e, index) {

                                        function idenfication() {
                                            if (e?.data?.empresa?.razaoSocial) {
                                                return subLimit(e?.data?.empresa?.razaoSocial.toUpperCase());
                                            } else {
                                                return subLimit(e?.data?.representante?.nome.toUpperCase());
                                            }
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>{idenfication()}</td>
                                                <td>{subLimit(e?.data?.tecnico?.nome.toUpperCase())}</td>
                                                <th>{e?.data?.planoGerenciamento.toUpperCase()}</th>
                                                <td>{e?.data?.data} - {e?.data?.horario}</td>
                                                <td>
                                                    <div className="d-grid gap-2 d-md-block text-center">
                                                        <BotaoEnviar item={e?.id + '&&&' + e?.data?.planoGerenciamento} />
                                                        <BotaoVisualizar item={e?.id} />
                                                        <BotaoExcluir item={e?.id} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <ToastContainer />

                    <div className="d-md-none d-block">
                        {
                           registros.filter(a => a?.data?.status === false).sort((x,y) => Date.parse(dataFiltro(y.data.data)) - Date.parse(dataFiltro(x.data.data))).map(function (e, index) {
                                function idenfication() {
                                    if (e?.data?.empresa?.razaoSocial) {
                                        return subLimit(e?.data?.empresa?.razaoSocial.toUpperCase());
                                    } else {
                                        return subLimit(e?.data?.representante?.nome.toUpperCase());
                                    }
                                }
                                return (
                                    <div className="row mb-4" key={index}>
                                        <div className="col-12 table-mobile">
                                            <p className="bg-dark text-white text-center py-2 m-0 fw-bold">{e?.data?.planoGerenciamento.toUpperCase()}</p>
                                            <p className="text-center py-1 m-0">{idenfication()}</p>
                                            <p className="text-center py-1 m-0">{subLimit(e?.data?.tecnico?.nome)}</p>
                                            <p className="text-center py-1 m-0">{e?.data?.data} - {e?.data?.horario}</p>
                                            <div className="d-block text-center m-auto py-2 button-mobile">
                                                <BotaoEnviar item={e?.id + '&&&' + e?.data?.planoGerenciamento} />
                                                <BotaoVisualizar item={e?.id} />
                                                <BotaoExcluir item={e?.id} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </main>
        </div>
    );
}
