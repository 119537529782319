import React from "react";
import ReactInputMask from "react-input-mask";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

export const METROS = ({ value, onChange }) => {
    return (
        <ReactInputMask
            mask="9999,99"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="m²"
            required
        />
    )
}

export const DECIMAL = ({ value, onChange }) => {
    return (
        <ReactInputMask
            mask="9999.99"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="kg"
        />
    )
}

export const CEP = ({ value, onChange }) => {
    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                value: onlyNumbers(event.target.value),
            }
        })
    }
    return (
        <ReactInputMask
            mask="99999-999"
            value={value}
            onChange={handleChange}
            className="form-control"
            placeholder="CEP"
            required
        />
    )
}

export const CELULAR = ({ value, onChange }) => {
    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                value: onlyNumbers(event.target.value),
            }
        })
    }
    return (
        <ReactInputMask
            mask="(99) 9 9999-9999"
            value={value}
            onChange={handleChange}
            className="form-control"
            placeholder="(xx) x xxxx-xxxx"
            required
        />
    )
}

export const CPF = ({ value, onChange }) => {
    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                value: onlyNumbers(event.target.value),
            }
        })
    }
    return (
        <ReactInputMask
            mask="999.999.999-99"
            value={value}
            onChange={handleChange}
            className="form-control"
            placeholder="CPF"
            required
        />
    )
}

export const CNPJ = ({ value, onChange }) => {
    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                value: onlyNumbers(event.target.value)
            }
        })
    }
    return (
        <ReactInputMask
            mask="99.999.999/9999-99"
            value={value}
            onChange={handleChange}
            className="form-control"
            placeholder="CNPJ"
            required
        />
    )
}