import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCUc3NxGbVeX-SKG9E5QY9I61wd6X36BQM",
  authDomain: "coaafi-gerador-documentos.firebaseapp.com",
  projectId: "coaafi-gerador-documentos",
  storageBucket: "coaafi-gerador-documentos.appspot.com",
  messagingSenderId: "38128334684",
  appId: "1:38128334684:web:774997741f363006363399"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();
